
import { Inject } from 'inversify-props';
import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';

import AuthService, { AuthServiceS } from '@/modules/auth/auth.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';

import USER_LEVELS from '@/modules/user/constants/user-levels.constant';

@Component
export default class HotelTransitionPage extends Vue {
    @Inject(UserServiceS)
    private userService!: UserService;

    @Inject(AuthServiceS)
    private authService!: AuthService;

    @Inject(RatesAnalysisFiltersServiceS)
    private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;

    @Inject(RatesFiltersServiceS)
    protected ratesFiltersService!: RatesFiltersService;

    @Inject(DocumentFiltersServiceS)
    protected documentFiltersService!: DocumentFiltersService;

    @Prop({
        required: true,
        type: Number,
    })
    hotelId!: number;

    @Watch('hotelId', { immediate: true })
    async initCurrentHotel(hotelId: number, oldId: number): Promise<void> {
        if (!this.userService.storeState.user) {
            return;
        }

        const isNeedToSwitch = this.userService.currentHotelId !== hotelId;
        const currentHotelId = hotelId || this.userService.storeState.user.currentHotelId;

        if (currentHotelId && this.$route.name) {
            if (oldId || !this.ratesAnalysisFiltersService.comparisonValues.length) {
                this.ratesAnalysisFiltersService.resetComparisonFilters();
            }

            const oldHotelId = this.userService.currentHotelId;

            if (isNeedToSwitch) {
                this.userService.currentHotelId = currentHotelId;
                await this.userService.switchCurrentHotel(currentHotelId);
            } else {
                this.userService.currentUserHotelInited = true;
            }

            if (oldHotelId !== currentHotelId) {
                this.authService.socketHandshake();
            }
        }

        const routeBranch = window.location.pathname.split('/')[1];
        const isHotelLevel = routeBranch === 'hotel';
        const isHotelSettings = routeBranch === 'settings' && !!hotelId;
        const isNeedToSetViewAs = this.userService.user?.viewAs !== USER_LEVELS.HOTEL;

        this.documentFiltersService.competitors = [];
        this.ratesFiltersService.setDefaultCompetitors();

        if (this.$route.query.provider) {
            this.ratesFiltersService.storeState.settings.provider = this.$route.query.provider as string;
        }

        if (isNeedToSetViewAs) {
            if (isHotelLevel || isHotelSettings) {
                this.userService.setViewAs(USER_LEVELS.HOTEL, hotelId);
            }
        }
    }
}
